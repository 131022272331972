<template>
<div id ="contact">
  <div class="vx-row">

        <!-- TITLE COLOR -->
        <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
        <!-- ABOUT CARD -->
                <vx-card                 title="İletişim"                 title-color="primary"                 subtitle=""                 code-toggler>
                    <!-- ACTION SLOT -->
                    <template slot="actions">
                        <feather-icon icon="MoreHorizontalIcon"></feather-icon>
                    </template>
                    
                    <!-- CONTACT BIO -->
                    <div class="user-bio">
                        <p>Bizi telefonla aradığınızda, Sabah 09:00 Aksam:17:30 Saatleri arasında ofislerimizin açık olduğunu lütfen unutmayın. Saat Dilimi GMT +3:00 İstanbul/TURKEY</p>
                    </div>

                    <br>
                    <!-- USER BIO -->
                    <div class="user-bio">
                        <p>Bilişim sektörüne 2000 yılında Teknolojide Güven Sloganıyla adım atan  Oluşum Bilgi İşlem Paz.San.Tic.Ltd.Şti. son teknolojiyi, rekabetçi fiyatla ve eksiksiz destek hizmetiyle Türk BT pazarına sunmak amacıyla kurulmuştur. Hem tedarikçi firmalara hem de kurumsal ve bireysel müşterilerine yalın ve direkt süreçlerle ulaşarak, sürekli maliyet avantajı oluşturmayı hedeflemektedir. Böylelikle, kaliteden ödün vermeksizin, en yeni teknolojiyi yansıtan ürün ve çözümlerini, en uygun fiyatla pazara sunabilmektedir. En zor projelerde bile müşterilerinin yanında olan Oluşum Bilgi İşlem Paz.San.Tic.Ltd.Şti. , sistem entegrasyon ve danışmanlık hizmetleri ile teknolojide güven sloganı çerçevesinde eksiksiz, tam çözüm sağlamaktadır.</p>
                    </div>

                    <!-- OTEHR DATA -->
                    <div class="mt-5">
                        <h6>Şirket Ünvanı:</h6>
                        <p>OLUŞUM BİLGİ İŞLEM Paz San. ve Tic. Ltd. Şti</p>
                    </div>
                    <div class="mt-5">
                        <h6>Kurulum Yılı:</h6>
                        <p>2000</p>
                    </div>

                    <div class="mt-5">
                        <h6>Adres:</h6>
                        <p>Perpa Ticaret Merkezi B Blok Kat:10 No :1811 Şişli , İstanbul / Türkiye</p>
                    </div>

                    <div class="mt-5">
                        <h6>Telefon:</h6>
                        <p><a href="tel:+902122107046">+90-212-210-70-46</a></p>
                    </div>
                    <div class="mt-5">
                        <h6>Gsm:</h6>
                        <p><a href="tel:+905337469222">+90-533-746-92-22</a></p>
                    </div>

                    <div class="mt-5">
                        <h6>Email:</h6>
                        <p><a href="mailto:info@olusumbilgiislem.com"> info@olusumbilgiislem.com</a></p>
                    </div>

                    
                    <div class="mt-5">
                        <h6>VKN:</h6>
                        <p>1640141738</p>
                    </div>
                    <div class="mt-5">
                        <h6>İş Bankası IBAN:</h6>
                        <i class="fa fa-try"></i><strong></strong><a href="mailto:#">TR69 0006 400000 1125 00251 367</a><br/>
                    </div>
                    <div class="mt-5">
                        <h6>Vakıf Bankası IBAN:</h6>
                        <i class="fa fa-try"></i><strong></strong><a href="mailto:#">TR13 0001 5001 5800 7305 8508 49</a><br/>
                    </div>

                    <div class="social-links flex mt-4">
                        <a href="https://www.facebook.com/olusumbi" target="_blank" ><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon" ></feather-icon></a>
                        <a href="https://www.twitter.com/olusumbilgi" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="TwitterIcon" ></feather-icon></a>
                        <a href="https://www.instagram.com/olusumbi" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="InstagramIcon" ></feather-icon></a>
                    </div>
                </vx-card>
            
        </div>

        <!-- TITLE COLOR -->
        <div class="vx-col w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 mb-base">
            <vx-card
                title="Bize Ulaşın"
                title-color ="warning"
                code-toggler>
                
                <section id="google_map_5" class="map_area-02">
          	      <iframe src="https:\\www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d96289.77136488848!2d28.933095116920168!3d41.04591854769575!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab739933e2f55%3A0xffd0deecb2a31c60!2sPerpa+Ticaret+Merkezi!5e0!3m2!1str!2s!4v1425488665865" width="100%" height="425" frameborder="0" style="border:0"></iframe>
                </section>
               
            </vx-card>
            <br>
            <!-- VERTICAL LAYOUT WITH ICON-->
            <vx-card title="Bize Sorun" title-color="danger" code-toggler>
				<div class="vx-row mb-6">
          <div class="vx-col w-full">
						<vs-input class="w-full" icon-pack="feather" icon="icon-home" icon-no-border label="Firma Adınız" v-model="form.company" />
					</div>
					<div class="vx-col w-full">
						<vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Adınız" v-model="form.name" />
					</div>
				</div>
				<div class="vx-row mb-6">
					<div class="vx-col w-full">
						<vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label="Email" v-model="form.mail" />
					</div>
				</div>
				<div class="vx-row mb-6">
					<div class="vx-col w-full">
						<vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border label="Mobile" v-model="form.tel" />
					</div>
				</div>
				<div class="vx-row mb-6">
					<div class="vx-col w-full">
						<vs-textarea class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label="Mesajınız" v-model="form.description" />
					</div>
				</div>
				
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-button class="mr-3 mb-2" :disabled="saveEnabled" @click="sendEmail">Gönder</vs-button>
						<vs-button color="warning" type="border" class="mb-2" @click="form.company = form.name = form.mail = form.tel = form.description = ''">Vazgeç</vs-button>
					</div>
				</div>
			</vx-card>
        </div>

        
		
        </div>
  
        </div>
</template>
<script>

//import babelPolyfill from 'babel-polyfill'
export default {
  data() {
      return {
        form: {
          company: "",
          name : "",
          tel :"",
          mail: "",
          description: "",
        },
        saveButtonClicked: false,
        maps:''
      }
    },
    methods: {
      saveProduct() {
        this.saveButtonClicked = true;
        //this.$store.dispatch("saveProduct", this.product)
      },
      sendEmail () {
      
      this.saveButtonClicked = true;
      const emailData = {
        email: this.form.mail,
        name: this.form.name,
        message: this.form.description
      }
      this.$store.dispatch('sendEmail', emailData)
      //this.form.name = ''
      //this.form.mail = ''
      //this.form.description = ''
    }
    },

    computed : {
        isLoading(){
            return {
            display: "none"
          }
        },
        saveEnabled(){
          return false
        }
    }

}
</script>